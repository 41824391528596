<template>
  <div class="page-header-mobile flex w-full items-center lg:hidden">
    <div class="burger-menu flex flex-1 items-center gap-x-4 pl-3">
      <button
        class="-mr-1"
        data-testid="mobile-nav-toggler"
        :aria-label="$t('header.open-menu')"
        @click="isMobileMenuActive = !isMobileMenuActive"
      >
        <Icon mode="svg" name="ci:hamburger-md" class="text-5xl" />
      </button>

      <AutobidRenderComponents
        :components="groupedComponents?.left ?? []"
        class="mx-0 flex items-center gap-x-4"
      />
    </div>

    <LayoutHeaderPartialsLogo
      :logo="data.data.layout?.header?.brand"
      data-testid="mobile-logo"
    />

    <div class="flex flex-1 items-center justify-end gap-x-4 pr-3">
      <AutobidRenderComponents
        :components="groupedComponents?.right ?? []"
        class="mx-0 flex items-center justify-end gap-x-4"
      />

      <div v-if="!authDisabled" class="relative flex">
        <common-dropdown
          x="right"
          menu-items-classes="bg-[var(--top-navigation-background)]"
        >
          <template #button>
            <span
              class="flex items-center"
              :aria-label="$t('header.toggle-profile-menu')"
            >
              <CommonIconUser />
            </span>
          </template>

          <template #items>
            <template v-if="isAuthed && tokenData">
              <HeadlessMenuItem>
                <span
                  class="block border-b px-2 py-1 text-center font-bold text-[var(--top-navigation-text-color)]"
                  data-testid="mobile-nickname"
                >
                  {{ tokenData.nickname }}
                </span>
              </HeadlessMenuItem>
            </template>

            <HeadlessMenuItem>
              <AutobidRenderComponents
                class="top-bar-profile-dropdown"
                :class="{
                  'text-[var(--top-navigation-text-color)]':
                    isNavbarTransparentVisible
                }"
                :components="groupedComponents.profile ?? []"
              />
            </HeadlessMenuItem>
          </template>
        </common-dropdown>
      </div>
    </div>
  </div>

  <span
    v-if="isAuthed && isBidBlocked && !authDisabled"
    class="fixed left-3 top-[calc(100vh-60px)] z-40 lg:hidden"
  >
    <common-dropdown x="left" y="top">
      <template #button="{ open }">
        <div ref="bidBlockedBtn" :data-open="open">
          <CommonIconBlock
            class="h-12 w-12 text-red"
            data-testid="mobile-bid-block-icon"
          />
        </div>
      </template>

      <template #items>
        <LayoutHeaderPartialsBiddingBlockedReason
          :class="{
            'text-[var(--top-navigation-text-color)]':
              isNavbarTransparentVisible
          }"
        />
      </template>
    </common-dropdown>
  </span>
</template>

<script lang="ts" setup>
import { useHeaderStore } from '@autobid/nuxt-pinia-store/store/useHeaderStore'
import { storeToRefs } from 'pinia'
import type { StrapiParsedComponent } from '@autobid/strapi-integration/typescript/strapi/Page'
import type { TopBarMobilePosition } from '@autobid/strapi-integration/typescript/strapi/Layout'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { ref, inject, computed, onMounted, onBeforeMount } from 'vue'
import { useToken } from '@autobid/nuxt-auth/src/composables/useToken'
import { useUserHasBidBlocked } from '@autobid/ui/composables/useIsUserHasBidBlocked'
import { usePageHeader } from '@autobid/ui/composables/usePageHeader'
import type { HeaderProps } from '@autobid/ui/composables/usePageHeader'

interface Props {
  data: HeaderProps
}

const props = defineProps<Props>()

const { openReasonOnAuthorize } = usePageHeader()
const { isMobileMenuActive } = storeToRefs(useHeaderStore())
const { closeMobileMenu } = useHeaderStore()
const { isAuthed } = useAutobidAuth()
const { tokenData } = useToken()
const isBidBlocked = useUserHasBidBlocked()
const bidBlockedBtn = ref<HTMLButtonElement>()
const isNavbarTransparentVisible = inject('isNavbarTransparentVisible', false)
const authDisabled = inject('authDisabled', false)

const groupedComponents = computed(() => {
  const result: Partial<Record<TopBarMobilePosition, StrapiParsedComponent[]>> =
    {}

  for (const component of props.data.data.layout.topMenu ?? []) {
    if (!result[component.props.mobilePosition]) {
      result[component.props.mobilePosition] = []
    }

    result[component.props.mobilePosition].push(component)
  }

  const logoutIndex = (result.profile ?? []).findIndex(
    (el) => el.props.wrapper === 'Logout'
  )

  if (logoutIndex >= 0) {
    const logoutElement = result.profile.splice(logoutIndex, 1)[0]
    result.profile.push(logoutElement)
  }

  return result
})

onMounted(() => {
  openReasonOnAuthorize(bidBlockedBtn.value)
  window.addEventListener('resize', closeMobileMenu)
})

onBeforeMount(() => {
  window.removeEventListener('resize', closeMobileMenu)
})
</script>

<style lang="scss">
.page-header-mobile {
  [role='menu'] {
    a,
    button {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
</style>
