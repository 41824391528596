<template>
  <transition
    enter-from-class="opacity-0 scale-50"
    enter-active-class="duration-300"
    enter-to-class="opacity-100 scale-100"
    leave-active-class="duration-300"
    leave-to-class="opacity-0 scale-50"
  >
    <button
      v-show="isVisible"
      class="fixed bottom-4 right-4 z-50 h-10 w-10 rounded-full bg-white shadow-sm"
      @click="goTop"
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        class="progress-wrapper"
        :style="`--progress: ${scrollProgress}`"
      >
        <circle class="fill-none stroke-[#cacaca]"></circle>
        <circle
          class="progress-fill -rotate-90 fill-none stroke-primary"
        ></circle>
      </svg>

      <Icon
        mode="css"
        name="mdi:chevron-down"
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-180 text-3xl text-primary"
      />
    </button>
  </transition>
</template>

<script lang="ts" setup>
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'
import { useHeaderStore } from '@autobid/nuxt-pinia-store/store/useHeaderStore'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const { scrollY, resolution } = storeToRefs(useBodyStore())
const { isMobileMenuActive } = storeToRefs(useHeaderStore())

const isVisible = computed(
  () =>
    scrollY.value > 0 &&
    (resolution.value.width > 768 || !isMobileMenuActive.value)
)
const scrollProgress = computed(() => {
  if (!isVisible.value || !document) return 0

  const maxScroll = document.body.scrollHeight - window.innerHeight

  return Math.round((scrollY.value / maxScroll) * 100)
})

const goTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>

<style lang="scss" scoped>
.progress-wrapper {
  --size: 40px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 2px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circuit: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circuit)) / 100);

  circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    stroke-linecap: round;
  }

  .progress-fill {
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circuit) - var(--dash));
  }
}

body {
  &.has-search-form {
    button {
      margin-bottom: 75px;
    }
  }

  &.has-search-form-auth {
    button {
      margin-bottom: 125px;
    }
  }

  &.has-search-form,
  &.has-search-form-auth {
    &.no-chips {
      button {
        @media (min-width: 768px) {
          margin-bottom: 0;
        }
      }
    }

    &.chips {
      button {
        @media (min-width: 768px) {
          margin-bottom: 55px;
        }
      }
    }

    &.no-chips,
    &.chips {
      button {
        @media (min-width: 1415px) {
          margin-bottom: 0;
        }
      }
    }
  }

  &.has-car-search,
  &.has-car-list {
    button {
      margin-bottom: 55px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }
  }

  &.has-car-list-in-auction {
    button {
      margin-bottom: 120px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }
  }
}
</style>
