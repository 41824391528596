<template>
  <div
    v-if="data"
    class="layout flex min-h-screen flex-col"
    :class="{
      'has-transparent-nav': data.layout?.isNavbarTransparent
    }"
    :style="{
      '--text-hover':
        data.layout?.isNavbarTransparent &&
        scrollY < TRANSPARENT_NAVBAR_THRESHOLD &&
        !isMobileMenuVisible
          ? 'var(--top-navigation-transparent-text-hover-color)'
          : 'var(--top-navigation-text-hover-color)'
    }"
  >
    <slot :layout-data="data" />

    <LayoutBackToTop v-if="data.layout?.showBackToTop" />
  </div>
</template>

<script lang="ts" setup>
import { onServerPrefetch } from 'vue'
import getLayoutQuery from '@autobid/strapi-integration/utils/getLayoutQuery'
import type { StrapiPageError } from '@autobid/strapi-integration/typescript/strapi/Page'
import { prepareStrapiError } from '@autobid/strapi-integration/utils/helpers'
import type { LayoutResponse } from '@autobid/strapi-integration/typescript/strapi/Layout'
import {
  parseStrapiLayouts,
  parseStrapiNavigation
} from '@autobid/strapi-integration/utils/content'
import { useQuery } from '@tanstack/vue-query'
import { storeToRefs } from 'pinia'
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'
import { useHeaderStore } from '@autobid/nuxt-pinia-store/store/useHeaderStore'
import { TRANSPARENT_NAVBAR_THRESHOLD } from '@autobid/ui/constants/TRANSPARENT_NAVBAR_THRESHOLD'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useStrapiLang } from '@autobid/strapi-integration/composable/useStrapiLang'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { useCmsConfig } from '@autobid/strapi-integration/composable/useCmsConfig'
import type { StrapiNavigationLink } from '../typescript/strapi/Navigation'

const emit = defineEmits(['error'])

const { strapiLang: lang } = useStrapiLang()
const cmsConfig = useCmsConfig()
const {
  query,
  variables,
  cacheKey: queryCK
} = getLayoutQuery(lang.value, cmsConfig)
const { isAuthed } = useAutobidAuth()
const { $customFetch } = useCustomFetch()

const { suspense, data } = useQuery(
  ['layoutQuery', lang],
  async () => {
    const data = await $customFetch<LayoutResponse>('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'cms',
        queryCK,
        query,
        variables
      }
    })

    if (data?.errors?.length) {
      emit(
        'error',
        prepareStrapiError({
          statusCode: 500,
          data: {
            errors: data.errors
          }
        })
      )

      return null
    }

    if (!data?.data?.layouts?.data?.length) {
      emit('error', {
        code: 404,
        message: 'Layout not found'
      } as StrapiPageError)

      return null
    }

    try {
      const navigation = parseStrapiNavigation(
        isAuthed.value,
        data.data.renderNavigation as StrapiNavigationLink[]
      )

      const layout = parseStrapiLayouts(data.data.layouts)

      return {
        navigation,
        layout
      }
    } catch (err) {
      emit('error', {
        code: 500,
        message: String(err)
      } as StrapiPageError)

      return null
    }
  },
  {
    refetchOnWindowFocus: false,
    onError: (err) => {
      emit('error', {
        code: err?.statusCode,
        message: err?.statusMessage
      } as StrapiPageError)
    }
  }
)
onServerPrefetch(suspense)

const { scrollY } = storeToRefs(useBodyStore())
const { isMobileMenuVisible } = storeToRefs(useHeaderStore())
</script>
